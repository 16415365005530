
.form__content-wrapper{
    padding:58px 112px;
    background-color:white;
    border-radius: 30px;
}
.form__content-title{
    font-size: 46.72px;
    margin-bottom: 32px;
    font-family: 'InterBold';
}
body[dir='rtl'] .form__content-title{
    font-family: 'CairoBold';
}
.form__content-form{
    margin-bottom: 20px;
}
.form__content-form-inputs-wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:32px;
    /* margin-bottom: 65px; */
}
.form__content-form-input{
    width: 100%;
    border-radius: 10px;
    background-color: white;
    color:#A0A0A0;
    border: 1.5px solid #D9D9D9!important;
    padding:20px;
    font-size: 19.03px;
    font-family: 'InterRegular';
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.form__content-form-input--margin{
    padding: 0px;
    width:100%;
    height:100%;
    border: 0px!important;
}
.form__content-form-input:focus{
    outline: 1px solid #d0c7c7;
}
.form__content-form-input-wrapper{
    position: relative;
    width:30%
}
.form__content-form-input-wrapper .form__content-form-input{
    width:100%
}
.form__content-form-input-icon{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.form__content-form-submit{
    font-size: 24px;
    color:white;
    background-color: var(--main-color);
    font-family: 'InterBold';
    width:384px;
    max-width:100%;
    height:76px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    border-radius: 10px;
    transition: var(--transition);
}

.form__content-form-submit:hover{
    opacity: .8;
}
body[dir='rtl'] .form__content-form-submit{
    font-family: 'CairoBold';
}
.form__content-form-input-text{
    font-size: 18.32px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor:pointer;
}
.form__content-uploaded-images-wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:30px;
    margin: 20px 0;
}
.form__content-uploaded-image-wrapper{
    width: 100%;
    height: 100%;
    border-radius: 25px;
    position: relative;
}
.form__content-uploaded-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.form__content-uploaded-image-delete-icon{
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius:50%;
    width:40px;
    height:40px;
    background-color: red;
    font-size: 18px;
    color:white;
    font-family: 'InterBold';
}
@media (max-width:991px) {
    .form__content-uploaded-images-wrapper{
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width:500px) {
    .form__content-uploaded-images-wrapper{
        display:grid;
        grid-template-columns: repeat(1,1fr);
        gap:30px;
    }
}
@media (max-width:1200px) {
    .form__content-form-inputs-wrapper{
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
        /* margin-bottom: 35px; */
    }
    .form__content-wrapper{
        padding: 38px 62px;
        border-radius: 20px
    }
}
@media (max-width:768px) {
    .form__content-form-inputs-wrapper{
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
        /* margin-bottom: 35px; */
    }
    .form__content-wrapper{
        padding: 28px;
        border-radius: 20px;
    }
    .form__content-title{
        font-size: 36.72px;
        text-align: center;
    }
}